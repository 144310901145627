
import { eoRendererMixin, metaDataMixin } from '~/mixins'

export default {
  mixins: [
    eoRendererMixin,
    metaDataMixin,
  ],
  async asyncData ({ $api, $config, store, route, error, params: { slug } }) {
    // Get content for this tag from EO Prepr environment
    const { data: { items } } = await $api.tags.getAll({
      domain: `${$config.preprEnvironmentDomainEo},${$config.preprEnvironmentDomainEva},${$config.preprEnvironmentDomainLazarus},${$config.preprEnvironmentDomainVisie},${$config.preprEnvironmentDomainGeneriek}`,
      tag: slug,
      limit: 1,
    })

    // Check if we found a valid publication
    if (!items || !items.length) {
      error({
        context: `Couldn't find tag: ${slug}.`,
        message: '(404) Tag niet gevonden',
        severity: 'info',
        statusCode: 404,
      })
      return false
    }

    const tag = items[0]

    // Set breadcrumbs
    store.dispatch('breadcrumbs/set', {
      path: route.path,
      width: 8,
      items: [
        {
          to: '/',
          label: 'Home',
        },
        {
          label: tag.title,
        },
      ],
    })

    return tag
  },
  data () {
    return {
      id: null,
      title: null,
      slug: null,
      hasMore: true,
      articles: [],
      social: {
        description: null,
        image: null,
        title: null,
      },
      seo: {
        description: null,
        title: null,
      },
    }
  },
  async fetch () {
    const { data: { items: articles, meta: { total } } } = await this.$api.articles.getAll({
      limit: 12,
      offset: this.articles.length,
      tag: this.slug,
    })

    this.articles.push(...articles)

    this.hasMore = this.articles.length < total
  },
}
